import * as React from 'react'
import * as styles from './BooksyButton.module.scss'

function BooksyButton() {
  const openBooksy = () => {
    const button = document.querySelector(
      '.booksy-widget-container > .booksy-widget-button'
    ) as HTMLElement
    button.click()
  }

  return (
    <div id="booksy" className={styles.booksyButton}>
      <button type="button" onClick={openBooksy}>
        <span className="text-lg">ZAREZERWUJ TERMIN</span>
      </button>
    </div>
  )
}

export default BooksyButton
