import * as React from 'react'
import './Navigation.scss'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import BooksyButton from '../BooksyButton/BooksyButton'

type MenuItem = {
  readonly name: string
  readonly path: string
}

const menuList: MenuItem[] = [
  {
    name: 'Cennik',
    path: '/cennik/'
  },
  {
    name: 'O nas',
    path: '/o-nas/'
  },
  {
    name: 'Kontakt',
    path: '/kontakt/'
  },
  {
    name: 'Regulamin',
    path: '/regulamin/'
  }
]

function Navigation() {
  return (
    <header className="navigation">
      <input
        type="checkbox"
        className="navigation__checkbox"
        id="navi-toggle"
      />

      <div className="navigation__header-logo">
        <Link to="/">
          <StaticImage
            src="../../images/logo-only-moon-small.webp"
            alt="Hekate logo"
            placeholder="blurred"
          />
        </Link>
      </div>

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="navi-toggle" className="navigation__button">
        <span className="navigation__icon">&nbsp;</span>
      </label>

      <div className="navigation__background" />

      <nav className="navigation__nav">
        <div className="navigation__logo">
          <Link to="/">
            <StaticImage
              src="../../images/logo-only-moon.webp"
              alt="Hekate logo"
              placeholder="blurred"
            />
          </Link>
        </div>
        <ul className="navigation__list">
          {menuList.map(({ name, path }) => (
            <li key={name} className="navigation__item">
              <Link to={path} className="navigation__link">
                {name}
              </Link>
            </li>
          ))}
        </ul>
        <BooksyButton />
      </nav>
    </header>
  )
}
export default Navigation
