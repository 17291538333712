import * as React from 'react'
import Navigation from '../Navigation/Navigation'

type LayoutProps = {
  readonly children: React.ReactNode
}

function Layout({ children }: LayoutProps) {
  return (
    <div className="w-screen h-screen bg-left-bottom bg-no-repeat relative">
      <Navigation />
      <main>{children}</main>
      {/* <footer>© {new Date().getFullYear()}, Hekate - Salon Psiej Urody</footer> */}
    </div>
  )
}

export default Layout
